<template>
  <span :class="['ty--label', tier.css]">{{ $t(tier.key) }}</span>
</template>

<i18n>
{
"en": {
"pos3": "Excellent",
"pos2": "Very Good",
"pos": "Good",
"neu": "Fair",
"neg": "Poor",
"gy": "No Reviews"
},
"ja": {
"pos3": "すばらしい",
"pos2": "大変良い",
"pos": "良い",
"neu": "普通",
"neg": "悪い",
"gy": "口コミなし"
}
}
</i18n>

<script>
import { tyGrades, tyToGrade } from '@/utils/constants/grading';

export default {
  name: 'TrustYouScoreLabel',
  props: {
    score: Number,
    tierKey: String, // neg, pos, neu, pos2, pos3, gy
  },
  computed: {
    tier() {
      if (this.tierKey) {
        return tyGrades[this.tierKey];
      }
      return this.score && tyGrades[tyToGrade(this.score)];
    },
  },
};
</script>

<style lang="scss" scoped>

  /* ------------- ty--label -------------- */
  .ty--label .score {
    color: #333333;
  }
  /* ------------- Negative ---------------- */
  .ty--label.neg {
    color: $danger;
  }

  /* -------------- Neutral ---------------- */
  .ty--label.neu {
    color: #A35E04;
  }

  .ty--label.neu .value {
    background: #A35E04;
  }

  /* ------------- Positive ---------------- */
  .ty--label.pos {
    color: #047205;
  }
</style>
