<template>
  <div class="page">
    <!--page title-->
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="is-3 title">
            {{ $t('Data Extraction') }}
          </h2>
        </div>
      </div>
    </section>
    <!--search bar-->
    <section class="has-background-secondary py-4 px-5">
      <div class="container">
        <SearchForm @search="fetchData" />
      </div>
    </section>
    <!--property detail-->
    <div class="section py-5">
      <div class="container">
        <div class="mb-3">
          <router-link
            :to="backRouter"
            class="is-flex is-align-items-center has-text-weight-bold has-text-primary"
          >
            <IconBase width="14" height="12" icon-name="back" class="m-b-0 m-r-2x">
              <IconBack />
            </IconBase>
            <span>{{ $t('Back') }}</span>
          </router-link>
        </div>
        <!--property detail-->
        <div
          v-if="property"
          class="property-detail"
        >
          <div class="mb-5">
            <div class="is-flex-tablet">
              <div class="has-text-grey mr-2">
                {{ property.propertyCode }}
              </div>
              <div class="has-text-weight-bold mr-2">
                {{ property.name }}
              </div>
              <div>
                <StarRatings :ratings="property.starRating" />
              </div>
            </div>
            <div class="is-flex-tablet mb-1">
              <div class="has-text-grey mr-2">
                {{ property.category }}
              </div>
              <PropertyLocation v-bind="property.location" />
            </div>
            <TrustYouReview class="mb-1" v-bind="property.trustYou" />
          </div>
          <!--collapse-->
          <a-collapse v-model:activeKey="activeKey" expand-icon-position="right" :bordered="false">
            <template #expandIcon="{ isActive }">
              <svg :style="`transform: rotate(${isActive ? 0 : 180}deg);`" width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 7L7 0L14 7H0Z" fill="#00596C" />
              </svg>
            </template>
            <!--Property Information-->
            <a-collapse-panel key="property" :header="$t('Property Information')">
              <div class="columns">
                <div class="column is-4">
                  <table class="property-table">
                    <tbody>
                      <tr>
                        <th>
                          {{ $t('Website') }}
                        </th>
                        <td style="word-break:break-all;">
                          {{ $filters.cell(property.website) }}
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t('Email') }}</th>
                        <td>{{ $filters.cell(property.email) }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('Phone') }}</th>
                        <td>{{ $filters.cell(property.phone) }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('Fax') }}</th>
                        <td>{{ $filters.cell(property.fax) }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('Check in') }}</th>
                        <td>{{ $filters.cell(checkinTime) }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('Check out') }}</th>
                        <td>{{ $filters.cell(checkoutTime) }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('Airport') }}</th>
                        <td>{{ $filters.cell(property.airport) }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('Chain') }}</th>
                        <td>{{ $filters.cell(property.chain) }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('Facilities') }}</th>
                        <td>{{ $filters.cell(facilities) }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('Status') }}</th>
                        <td>{{ status }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="column is-8 content">
                  <h4 class="has-text-weight-bold mb-1">
                    {{ $t('Property Description') }}
                  </h4>
                  <p v-html="property.description" />
                  <h4 class="has-text-weight-bold mb-1">
                    {{ $t('Check In/Check Out Policy') }}
                  </h4>
                  <p v-html="property.policy" />
                </div>
              </div>
            </a-collapse-panel>
            <!--room information-->
            <a-collapse-panel key="room" :header="$t('Room Information')">
              <a-spin :spinning="isFetchingReservation">
                <div v-if="reservations && reservations.length > 0">
                  <PropertyReservation
                    v-for="(reservation, index) in reservations"
                    :key="`${reservation.id}-${index}`"
                    :reservation="reservation"
                    :loading="isFetchingReservation"
                  />
                </div>
                <a-empty v-else>
                  <template #description>
                    <h1>{{ $t('No data') }}</h1>
                  </template>
                </a-empty>
              </a-spin>
            </a-collapse-panel>
            <!--price-->
            <a-collapse-panel
              key="price"
              :header="`${$t('Price')} (${$filters.date(checkin, {format: dateFormat})})`"
            >
              <PropertyRoomPrices :room-prices="roomPrices" :loading="isFetchingRoomPrices" />
            </a-collapse-panel>
            <a-collapse-panel
              v-if="reviews"
              key="review"
              :header="$t('Guest Review')"
            >
              <div class="columns">
                <div class="column is-3">
                  <TrustYouRating
                    :condensed="true"
                    v-bind="property.trustYou"
                    size="s"
                    class="m-b-5x"
                  />

                  <div
                    class="has-text-black is-size-6 has-text-weight-bold m-b-5x"
                  >
                    {{ reviews.summary.text }}
                  </div>

                  <div>
                    <TrustYouHotelType
                      v-for="it in reviews.hotelTypeList"
                      :key="it.categoryId"
                      v-bind="it"
                      text-type="shortText"
                    />
                  </div>
                </div>
                <div class="column is-9">
                  <TrustYouHighLight
                    v-for="it in reviews.categoryList"
                    :key="it.categoryId"
                    :category="it"
                  />
                </div>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </div>
    </div>
  </div>
</template>
<i18n src="./locales.json"></i18n>
<script>
import { getDateFormat } from '@/utils/dates';
import { getResponseError } from '@/utils/util';
import SearchForm from '@/views/simulations/components/extractions/SearchForm';
import IconBack from '@/components/icons/IconBack';
import StarRatings from '@/components/StarRatings';
import PropertyLocation from '@/components/PropertyLocation';
import TrustYouReview from '@/components/TrustYouReview';
import TrustYouRating from '@/components/TrustYouRating';
import TrustYouHotelType from '@/components/TrustYouHotelType';
import TrustYouHighLight from '@/components/TrustYouHighLight';
import PropertyRoomPrices from '@/views/simulations/components/extractions/PropertyRoomPrices';
import PropertyReservation from '@/views/simulations/components/extractions/PropertyReservation';
import previousRoute from '@/components/mixins/previousRoute';

export default {
  name: 'ExtractionsProperty',
  components: {
    PropertyReservation,
    PropertyRoomPrices,
    SearchForm,
    IconBack,
    StarRatings,
    PropertyLocation,
    TrustYouReview,
    TrustYouRating,
    TrustYouHotelType,
    TrustYouHighLight,
  },
  mixins: [previousRoute],
  data() {
    return {
      property: null,
      reservations: [],
      isFetchingReservation: false,

      roomPrices: [],
      isFetchingRoomPrices: false,
      inProgress: false,

      activeKey: ['property', 'room', 'price', 'review'],

      menuName: 'simulation-extractions',
    };
  },
  computed: {
    backRouter() {
      return {
        name: 'simulation-extractions',
        query: this.previousRouteQuery || this.$route.query,
      };
    },
    checkinTime() {
      return this.property?.checkinTime ? this.property?.checkinTime.split('.')[0] : '';
    },
    checkoutTime() {
      return this.property?.checkoutTime ? this.property?.checkoutTime.split('.')[0] : '';
    },
    facilities() {
      const { facilities } = this.property;

      if (!facilities) {
        return '';
      }

      const { private: priv = [], public: pub = [] } = facilities || {};
      const merged = [...priv, ...pub];

      return merged.map((facility) => facility.label).join(', ');
    },
    status() {
      return this.property?.isPropertyOpened ? this.$t('Open') : this.$t('Close');
    },
    reviews() {
      return this.property?.reviews;
    },
    checkin() {
      return this.$route.query.checkin;
    },
    dateFormat() {
      return getDateFormat();
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'simulation-extractions-property') this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.getProperty();
      this.getReservations();
      this.getRoomPrices();
    },
    async getProperty() {
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
        this.property = null;
        const { data } = await this.$store.dispatch('extractions/getProperty', this.$route.params.id);
        this.property = data;
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    async getReservations() {
      try {
        this.isFetchingReservation = true;
        this.reservations = [];
        const { data } = await this.$store.dispatch('extractions/getReservation', {
          id: this.$route.params.id,
          checkin: this.$route.query.checkin,
        });
        this.reservations = data;
      } catch (e) {
        this.$message.error(getResponseError(e));
      } finally {
        this.isFetchingReservation = false;
      }
    },
    async getRoomPrices() {
      try {
        this.isFetchingRoomPrices = true;
        this.roomPrices = [];
        const { data, inProgress } = await this.$store.dispatch('extractions/getRoomPrices', {
          id: this.$route.params.id,
          ...this.$route.query,
        });
        this.roomPrices = data;
        this.inProgress = inProgress;

        if (inProgress) {
          this.getRoomPrices();
          return;
        }

        this.isFetchingRoomPrices = false;
      } catch (e) {
        this.$message.error(getResponseError(e));
        this.isFetchingRoomPrices = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none;
    border-top: 1px solid #DCDCDC;
  }
  .ant-collapse-borderless {
    background: #FFFFFF;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #00596C;
  }
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
  }
}
.property-table {
  th {
    width: 110px;
    padding-bottom: 5px;
  }
  td {
    padding-bottom: 5px;
  }
}
.content {
  b {

  }
}
.page {
  background-color: #F5F5F5;
  min-height: 100vh;
}
.property-detail {
  padding: 15px;
  border: 1px solid #DCDCDC;
  border-radius: 5px;
  background-color: #ffffff;
}
</style>
