<template>
  <div class="score-bar">
    <div class="score" :class="bgClass" :style="scoreStyle" />
  </div>
</template>

<script>
export default {
  name: 'ScoreBar',
  props: {
    score: {
      type: Number,
      default: 0,
    },
    bgClass: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    scoreStyle() {
      return `width:${this.score}%;`;
    },
    // bgClass () {
    //   if (this.score > 70) {
    //     return 'has-background-success'
    //   } else if (this.score > 50) {
    //     return 'has-background-yellow'
    //   } else {
    //     return 'has-background-danger'
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>

.score-bar {
  height: 14px;
  background-color: #dbdbdb;
}

.score {
  height: 14px;
}

.default {
  background-color: #689cd4;;
}

.neg {
  background-color: #ee8e8e;
}

.neu {
  background-color: #f8d867;
}

.pos {
  background-color: #9acf89;
}
</style>
