<template>
  <div class="mb-4">
    <div>
      <span class="is-size-6 hast-text-grey mr-2">{{ code || '-' }}</span>
      <span class="has-text-weight-bold">{{ reservation.description }}</span>
    </div>
    <div class="mb-2">
      <span class="mr-2">{{ $t('beds') }}: {{ $filters.cell(beds) }}</span>
    </div>
    <a-table
      row-key="name"
      :columns="columns"
      :data-source="rows"
      :loading="loading"
      :pagination="false"
    >
      <template #name="{ record }">
        <div class="has-text-weight-bold">
          {{ record.name }}
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { getDateMonthFormat } from '@/utils/dates';

export default {
  name: 'PropertyReservation',
  props: {
    reservation: {
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    code() {
      return this.reservation?.supplierRoomCode;
    },
    name() {
      return this.reservation?.description || this.reservation?.supplierDescription;
    },
    beds() {
      if (!this.reservation?.beds) {
        return '';
      }
      return Object.keys(this.reservation?.beds).join(', ');
    },
    size() {
      return this.reservation?.size;
    },
    columns() {
      const columns = [
        {
          title: this.$t('Reservation'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
      ];

      const dateFormat = getDateMonthFormat();

      if (this.reservation?.data) {
        const keys = Object.keys(this.reservation?.data);

        for (const key of keys) {
          columns.push({
            title: this.$filters.date(key, { format: dateFormat }),
            dataIndex: key,
          });
        }
      }

      return columns;
    },
    rows() {
      if (!this.reservation?.data) {
        return [];
      }

      const { data } = this.reservation || {};
      const months = Object.keys(data);
      const rows = {
        total: { name: this.$t('Total no of Booking') },
        los: { name: 'LOS' },
        adr: { name: 'ADR' },
        arpp: { name: 'ARPP' },
        dor: { name: 'DOR' },
      };

      for (let i = 0; i < months.length; i += 1) {
        const month = months[i];
        const monthData = data[month];

        rows.total[month] = this.$filters.number(monthData.total);
        rows.los[month] = this.$filters.number(monthData.los);
        rows.adr[month] = this.$filters.number(monthData.adr);
        rows.arpp[month] = this.$filters.number(monthData.arpp);
        rows.dor[month] = this.$filters.number(monthData.dor);
      }

      return Object.values(rows);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-table-tbody > tr > td {
    border-color: transparent;
  }
}
</style>
