<template>
  <a-table
    row-key="roomCode"
    :columns="columns"
    :data-source="roomPrices"
    :loading="loading"
    :pagination="false"
  >
    <template #roomCode="{ record }">
      <div>
        {{ record.roomCode || '-' }}
      </div>
    </template>
    <template #description="{ record }">
      {{ record.description }}
    </template>
    <template #food="{ record }">
      {{ record.food }}
    </template>
    <template #rate="{ record }">
      {{ $filters.currencyObj({
        value: record.rate,
        currency: record.currency
      }) }}
    </template>
  </a-table>
</template>

<script>
export default {
  name: 'PropertyRoomPrices',
  props: {
    roomPrices: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('Code'),
          dataIndex: 'roomCode',
          slots: { customRender: 'roomCode' },
        },
        {
          title: this.$t('Room'),
          dataIndex: 'description',
          slots: { customRender: 'description' },
        },
        {
          title: this.$t('Food'),
          dataIndex: 'food',
          slots: { customRender: 'food' },
        },
        {
          title: this.$t('Price'),
          dataIndex: 'rate',
          slots: { customRender: 'rate' },
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-table-tbody > tr > td {
    border-color: transparent;
  }
}
</style>
