<template>
  <div class="trustyou-highlight">
    <div class="columns">
      <div class="column is-narrow">
        <div class="category-name">
          {{ categoryName }}
        </div>
      </div>
      <div class="column is-narrow">
        <ScoreBar class="score-bar-narrow" :score="category.score" :bg-class="category.sentiment" />
      </div>
      <div class="column">
        <span v-for="highlightList in category.highlightList" :key="highlightList.text"
              class="m-r"
        >"{{ highlightList.text }}"</span>
        <transition name="view-fade">
          <div v-if="opened" class="sub-category-list">
            <div
              v-for="(sDes,index) in category.summarySentenceList"
              :key="index"
              class="sub-category-item"
            >
              <i class="ty-icon"
                 :class="{
                   'ty-icon-ok': (sDes.sentiment == 'pos' || sDes.sentiment == 'neu'),
                   'ty-icon-remove': sDes.sentiment == 'neg',
                   'ty-icon-radio': sDes.sentiment == 'mixed'
                 }"
              />
              {{ sDes.text }}
            </div>
          </div>
        </transition>
      </div>
      <div class="column is-narrow">
        <span class="action" @click="opened=!opened">{{ actionText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ScoreBar from './ScoreBar';

export default {
  name: 'TrustYouHighLight',
  components: { ScoreBar },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    actionText() {
      return this.opened ? '-' : '+';
    },
    categoryName() {
      return this.category.categoryName === 'バー＆飲料'
        ? 'バー'
        : this.category.categoryName;
    },
  },
};
</script>

<style lang="scss" scoped>

$card-border: 1px solid #dbdbdb;
.category-name {
  width: 130px;
}

.score-bar-narrow {
  width: 130px;
  margin-right: 30px;
  margin-top: 5px;
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #dbdbdb;
  border: $card-border;
  color: #888;
  text-align: center;
  font-size: 1rem;
  line-height: 1;
}

.sub-category-list {
  margin-top: 10px;
}

.sub-category-item {
  margin-bottom: 5px;
  color: #aeaeae;
}
</style>
