<template>
  <p>
    <i :class="['ty-icon', icon]" class="icon_color" />
    <span class="is-size-7" v-html="output" />
  </p>
</template>

<script>
export default {
  name: 'TrustYouHotelType',
  props: {
    categoryId: String,
    shortText: String,
    text: String,
    sentiment: String,
    textType: {
      type: String,
      default: 'text',
    },
  },
  computed: {
    icon() {
      return `ty-icon-${this.categoryId}`;
    },
    output() {
      return this[this.textType];
    },
  },
};
</script>

<style lang="scss" scoped>
$pos-color: #047205;

  .ty-icon {
    margin-right: $bleed;
  }
  p {
    white-space: normal;
    &:not(:last-child) {
      margin-bottom: $bleed;
    }
  }
  p /deep/ pos {
    color: $pos-color;
    font-weight: 600;
  }
  .icon_color{
    color: $pos-color;
  }
</style>
